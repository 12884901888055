import React, { useState, useRef, useEffect } from "react";
import DataGrid, { Button as Pager, Paging, Scrolling, Grouping, GroupPanel, SearchPanel, Toolbar, MasterDetail, Summary, TotalItem, Column, Selection } from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import { SelectBox, DateBox, TextBox, DropDownBox, Popover } from "devextreme-react";
import { Popup } from "devextreme-react/popup";
import utils, { loading, closeLoading } from "../../../utils/common";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import baseapi from "../../../api/baseapi";
import CustomizedLookup from "../../../components/lookup/Lookup";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import { menuOptions, moduleOptions, userOptions, actionOptions } from "../../../utils/lookupstore";

export default function AuditTrailListing() {
	const keyDetail = "CreatedDate";

	const datagridRef = useRef(null);
	const tempForm = useRef(null);
	const [dropDownList, setDropDownList] = useState({
		reportList: []
	});
	const currentToken = localStorage.getItem("Authorization").substring(7);
	const userID = JSON.parse(localStorage.getItem("data"));
	const [mainDataSource, setMainDataSource] = useState([]);
	const [detailDataSource, setDetailDataSource] = useState([]);
	// const [openSpecific, setOpenSpecific] = useState();
	const [openReportPop, setOpenReportPop] = useState(false);
	const [reportName, setReportName] = useState("");
	const [printRepDisabled, setPrintRepDisabled] = useState(true);
	const popupMessageRef = useRef(null);

    const [fromDate, setFromDate] = useState(
		new Date(new Date().setDate(1)).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
	const [toDate, setToDate] = useState(
		new Date(new Date()).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
    const [paramValue, setParamValue] = useState([
		{
			ParameterName: "FromDate",
			ParameterValue: utils.dateboxFormat(fromDate)
		},
		{
			ParameterName: "ToDate",
			ParameterValue: utils.dateboxFormat(toDate)
		},
		// {
		// 	ParameterName: "ReportName",
		// 	ParameterValue: "GL - Ledger (Standard)"
		// },
		// {
		// 	ParameterName: "ReportID",
		// 	ParameterValue: "15cdsGV76JY%3d"
		// }
	]);    
	const fromTransTypeLookupRef = useRef(null);
	const toTransTypeLookupRef = useRef(null);
	const [fromTransTypeList, setFromTransTypeList] = useState([]);
	const [toTransTypeList, setToTransTypeList] = useState([]);
	const [selectedTransType, setSelectedTransType] = useState(0);
	const [openTransTypeRange, setOpenTransTypeRange] = useState();
    const fromUserIDLookupRef = useRef(null);
	const toUserIDLookupRef = useRef(null);
	const [fromUserIDList, setFromUserIDList] = useState([]);
	const [toUserIDList, setToUserIDList] = useState([]);
	const [selectedUserID, setSelectedUserID] = useState(0);
	const [openUserIDRange, setOpenUserIDRange] = useState();
    const fromModuleLookupRef = useRef(null);
	const toModuleLookupRef = useRef(null);
	const [fromModuleList, setFromModuleList] = useState([]);
	const [toModuleList, setToModuleList] = useState([]);
	const [selectedModule, setSelectedModule] = useState(0);
	const [openModuleRange, setOpenModuleRange] = useState();
	const [selectedAction, setSelectedAction] = useState(0);

	const gridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const currentFocusIndex = useRef(null);
	const dropdownRef = useRef(null);
	const pagingIndex = useRef({
		loading: false,
		page: 1
	});
    
    const filterTypes = [
		{
			ID: 0,
			Type: "No Filter"
		},
		{
			ID: 1,
			Type: "Filter By Range"
		},
		{
			ID: 2,
			Type: "Filter By Multi-Select"
		}
	];

	useEffect(() => {
		Promise.allSettled([menuOptions.store.load(), moduleOptions.store.load(), userOptions.store.load(), actionOptions.store.load()]).then(
			(lists) => {
				setDropDownList({
					menuList: lists[0].value.data,
					moduleList: lists[1].value.data,
					userList: lists[2].value.data,
                    actionList: lists[3].value.data,
				});
				// console.log(lists[0].value.data)
			}
		);
	}, []);

    function valueOnChange(e, type = null) {
		try {
			if (e.value !== undefined) {
				const dataField = e.element.getAttribute("dataField");
				setPrintRepDisabled(true);
				if (dataField === "FromDate") {
					setFromDate(utils.dateboxFormat(e.value));
				} else if (dataField === "ToDate") {
					setToDate(utils.dateboxFormat(e.value));
				} else if (dataField === "FilterNumber") {
					if (e.value === 1) {
						switch (type) {
							case "TransType":
								setSelectedTransType(e.value);
								setOpenTransTypeRange(true);
								break;
							case "UserID":
								setSelectedUserID(e.value);
								setOpenUserIDRange(true);
								break;
							case "Module":
								setSelectedModule(e.value);
								setOpenModuleRange(true);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
					if (e.value === 2) {
						switch (type) {
							case "TransType":
								setSelectedTransType(e.value);
								break;
							case "UserID":
								setSelectedUserID(e.value);
								break;
							case "Module":
								setSelectedModule(e.value);
								break;
							case "Action":
								setSelectedAction(e.value);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
				}

				if (dataField !== "FilterNumber" && dataField !== "FromDate" && dataField !== "ToDate") updateParamValue(dataField, e.value);
			}
		} catch (err) {
			console.log(err);
		}
	}

	// const handlePrintBtn = (e) => {
	// 	if (reportName) {
	// 		baseapi.httpget(`${utils.ReportURL}/api/Report/getReportDetailsByReportName?reportID=${reportName}&accessToken=${currentToken}`).then((response) => {
	// 			const { data } = response;
	// 			data.map((items) => {
	// 				confirmReportParameters(items.ID, items.ViewerAccessURL);
	// 			});
	// 		});
	// 	}
	// };

	// const onInitialized = () => {
	// 	loading("Loading Data");
	// 	mainRef.current.getMain();
	// 	if (datagridRef.current) {
	// 		const dataGridInstance = datagridRef.current.instance;
	// 		dataGridInstance.getVisibleRows().forEach((row) => {
	// 			if (row.rowType === "data" && row.isExpanded) {
	// 				dataGridInstance.collapseRow(row.key);
	// 			}
	// 		});
	// 	}
	// };    

	const onInitialized = () => {
		// var parameters = `?userID=${userID?.userID}&reportListingType=${reportListing}&reportName=${returnSelectedValue("ReportName")}`;
		loading();

		tempForm.current = [...paramValue];

		tempForm.current.forEach((param) => {
			if (param.ParameterName.includes("List") && Array.isArray(param.ParameterValue)) {
				param.ParameterValue = param.ParameterValue.join("$%");
			}

			if (typeof param.ParameterValue !== "string") {
				param.ParameterValue = String(param.ParameterValue);
			}
		});

		var copied = tempForm.current;
		console.log(copied);

		baseapi
			.httppost(`/api/AuditTrail/Get?FromDate=${utils.dateboxFormat(fromDate)}&ToDate=${utils.dateboxFormat(toDate)}`, copied)
			.then((response) => {
				const { data } = response;
				// console.log(data);
				if (data !== null && data !== undefined) {
					var mainDataArray = [];
                    var detailsDataArray = [];
					// data.map((e) => {
					// 	console.log(e.DocumentID !== "");
					// });

					for (var i = 0; i < data.length; i++) {
                        // console.log(data[i]);
						// if (data[i].DocumentID !== "")
						mainDataArray.push(data[i].Parent);

                        if(data[i].Children.length > 0) {
                            for (var j = 0; j < data[i].Children.length; j++) {
                                // console.log(data[i].Details[j]);
                                //if (data[i].Details[j].DocumentID !== null)
                                detailsDataArray = detailsDataArray.concat(data[i].Children[j]);
                            }
                        }
					}
					setMainDataSource(mainDataArray);
					setDetailDataSource(detailsDataArray);

					// if (data.length > 0) {
					// 	setPrintRepDisabled(false);
					// }
				}

				if (data.status) {
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.message,
						type: "Danger"
					});
				}

				closeLoading();
			})
			.catch((e) => {
				// closeLoading();
				console.log(e);
			});

		tempForm.current.forEach((param) => {
			if (param.ParameterName === "IsZero") {
				param.ParameterValue = param.ParameterValue === "true";
			}
		});
	};

	const getTasks = (key) =>
		new DataSource({
			store: new ArrayStore({
				data: detailDataSource,
				key: keyDetail
			}),
			filter: [keyDetail, "=", key]
		});

	function dataTemplate(props) {
		// console.log(props.data);
		const dataSource = getTasks(props.data.key);

        return (
            <DataGrid dataSource={dataSource} showBorders={true} allowColumnResizing={true} allowColumnReordering={false} columnAutoWidth={true} paging={false} >
                <Column key={"TableName"} dataField={"TableName"} />
                <Column key={"FieldName"} dataField={"FieldName"} />
                <Column key={"OldValue"} dataField={"OldValue"} />
                <Column key={"NewValue"} dataField={"NewValue"} />
            </DataGrid>
        );
	}

    useEffect(() => {
		const toDateObj = new Date(toDate);
		const fromDateObj = new Date(fromDate);

		if (fromDateObj > toDateObj) {
			utils.displayPopupMessage(popupMessageRef, {
				visible: true,
				message: "To Date cannot be earlier than From Date.",
				type: "Warning"
			});

			const setDate = new Date(); // current date

			if (fromDateObj > setDate) {
				setToDate(utils.dateboxFormat(fromDate));
			} else {
				setToDate(utils.dateboxFormat(setDate));
			}
		}
	}, [toDate, fromDate]);

    const updateParamValue = (key, value) => {
		setParamValue((prevParamValue) => {
			const updatedParamValue = [...prevParamValue]; // Create a copy of the previous state array

			// Find the index of the item with the matching key
			const index = updatedParamValue.findIndex((item) => item.ParameterName === key);

			if (index !== -1) {
				// Update the existing item if it exists
				updatedParamValue[index] = { ParameterName: key, ParameterValue: value };
			} else {
				// Add a new item if the key doesn't exist
				updatedParamValue.push({ ParameterName: key, ParameterValue: value });
			}

			return updatedParamValue;
		});
	};

	const removeParamValue = (key) => {
		setParamValue((prevParamValue) => {
			// Filter out the item with the matching ParameterName
			var updatedParamValue = null;
			updatedParamValue = prevParamValue.filter((item) => item.ParameterName !== key);
			return updatedParamValue;
		});
	};

	const returnSelectedKeys = (type) => {
		const paramObject = paramValue.reduce((acc, curr) => {
			acc[curr.ParameterName] = curr.ParameterValue;
			return acc;
		}, {});

		let selectedKeys = [];

		if (type === "TransType" && paramObject["TransTypeList"]) {
			selectedKeys = ensureArray(paramObject["TransTypeList"]);
		} else if (type === "UserID" && paramObject["UserIDList"]) {
			selectedKeys = ensureArray(paramObject["UserIDList"]);
		} else if (type === "Module" && paramObject["ModuleList"]) {
			selectedKeys = ensureArray(paramObject["ModuleList"]);
		} else if (type === "Action" && paramObject["ActionList"]) {
			selectedKeys = ensureArray(paramObject["ActionList"]);
		}

		return selectedKeys;
	};

	const ensureArray = (value) => {
		if (Array.isArray(value)) {
			return value;
		} else {
			return value.split("$%");
		}
	};

	const returnSelectedValue = (type) => {
		const filtered = paramValue.filter((x) => x.ParameterName === type);
		return filtered.length > 0 ? filtered[0].ParameterValue : null;
	};

	const returnDropDownList = (type) => {
		if (type === "TransType") {
			return dropDownList.menuList;
		} else if (type === "UserID") {
			return dropDownList.userList;
		} else if (type === "Module") {
			return dropDownList.moduleList;
		} else if (type === "Action") {
			return dropDownList.actionList;
		} 
	};

    function clearFilters () {
        removeParamValue("TransTypeList");
        removeParamValue("FromTransType");
        removeParamValue("ToTransType");
        setSelectedTransType(0);
        setSelectedUserID(0);
        removeParamValue("UserIDList");
        removeParamValue("FromUserID");
        removeParamValue("ToUserID");
        setSelectedModule(0);
        removeParamValue("ModuleList");
        removeParamValue("FromModule");
        removeParamValue("ToModule");
        setSelectedAction(0);
        removeParamValue("ActionList");
    }

	function DataGridMultiRender(type) {
		let selectedKeys = returnSelectedKeys(type);
		// console.log(type);

		return (
			<div className='customized-lookup-container'>
				<div className='customized-lookup-search-container'>
					<div>
						<TextBox
							ref={searchBoxRef}
							placeholder='Search...'
							width={250}
							valueChangeEvent='keyup'
							showClearButton={true}
							onValueChanged={(e) => {
								gridRef.current.instance.searchByText(e.value);
								// updateDropDownList(e.value, type, pagingIndex.current["page"], false);
							}}
							onKeyDown={(e) => {
								// console.log("key down", e.event)
								if (e.event.key === "ArrowDown") {
									gridRef.current.instance.focus();
								}
							}}
						/>
					</div>

					<div className='customized-lookup-btn-section'>
						<Button
							text='Clear'
							onClick={(e) => {
								// if (type === "Project") {
								// 	removeParamValue("ProjectList");
								// } else if (type === "Salesman") {
								// 	removeParamValue("SalesList");
								// } else if (type === "Area") {
								// 	removeParamValue("AreaList");
								// } else if (type === "ChartAccount") {
								// 	removeParamValue("ChartList");
								// } else if (type === "Supplier") {
								// 	removeParamValue("SuppList");
								// } else if (type === "Customer") {
								// 	removeParamValue("CustList");
								// }

								if (searchBoxRef.current !== null) searchBoxRef.current.instance.reset();
								// refreshLookupData(type);
							}}
						/>
					</div>

					<div>
						<Button
							style={{ marginLeft: "5px" }}
							text='Clear Filter'
							stylingMode='text'
							type='default'
							onClick={() => {
								if (type === "TransType") {
									removeParamValue("TransTypeList");
									removeParamValue("FromTransType");
									removeParamValue("ToTransType");
									setSelectedTransType(0);
								} else if (type === "UserID") {
									setSelectedUserID(0);
									removeParamValue("UserIDList");
									removeParamValue("FromUserID");
									removeParamValue("ToUserID");
								} else if (type === "Module") {
									setSelectedModule(0);
									removeParamValue("ModuleList");
									removeParamValue("FromModule");
									removeParamValue("ToModule");
								} else if (type === "Action") {
									setSelectedAction(0);
									removeParamValue("ActionList");
								} 
							}}
							elementAttr={{ dataField: "FilterNumber" }}
						/>
					</div>
				</div>
				<DataGrid
					ref={gridRef}
					height={200}
					className={"lookup-datagrid gl-listing-datagrid"}
					showBorders={true}
					dataSource={returnDropDownList(type)}
					scrolling={{ columnRenderingMode: "standard", showScrollbar: "onHover" }}
					columnChooser={{ enabled: false }}
					allowColumnResizing={true}
					allowColumnReordering={true}
					hoverStateEnabled={true}
					paging={{ enabled: false }}
					keyExpr='code'
					focusedRowEnabled={true}
					focusedRowKey={selectedKeys}
					selectedRowKeys={selectedKeys}
					onSelectionChanged={(e) => dataGridOnSelectionChanged(e, type)}
					// onContentReady={(element) => {
					// 	const scrollable = element.component.getScrollable();
					// 	if (scrollable !== undefined) {
					// 		scrollable.on("scroll", function (e) {
					// 			if (e.reachedBottom) {
					// 				if (pagingIndex.current["loading"] === false) {
					// 					// console.log("page", pagingIndex.current["page"]);
					// 					pagingIndex.current["loading"] = true;
					// 					// updateDropDownList("", type, pagingIndex.current["page"], true);
					// 					// console.log(e);
					// 				}
					// 			}
					// 		});
					// 	}
					// }}
					onKeyDown={(e) => {
						// if(e.event.key === "Enter"){
						//     setDropdownOpened(false);
						// }
						if (e.event.key === "ArrowUp") {
							// If focus is one the first row then brings focus back to the search box
							if (currentFocusIndex.current === 0) {
								if (type === "TransType") {
									removeParamValue("TransTypeList");
								} else if (type === "UserID") {
									removeParamValue("UserIDList");
								} else if (type === "Module") {
									removeParamValue("ModuleList");
								} else if (type === "Action") {
									removeParamValue("ActionList");
								}
							}
						}
					}}
					onFocusedRowChanging={(e) => {
						if (e.event === null) {
							e.cancel = true;
						} else if (e.event.key === undefined) {
							e.cancel = true;
						}
					}}
					// onFocusedRowChanged={(e) => {
					//   const data = e.row.data;
					//   currentFocusIndex.current = e.rowIndex;
					//   if(e.event.key === "Enter") {
					//     setSelectedCustKey(data.id);
					//   }
					// }}
				>
					<Column
						dataField='code'
						elementAttr={{
							dataField: type === "TransType" ? "TransTypeCode" : type === "UserID" ? "UserIDCode" : type === "Module" ? "ModuleCode" : "ActionCode"
						}}
						caption={type === "TransType" ? "Trans Type Code" : type === "UserID" ? "User Code" : type === "Module" ? "Module Code" : "Action"}
						visible={true}
						alignment='left'
						allowGrouping={false}
					/>

					<Column
						dataField='name'
						elementAttr={{
							dataField: type === "TransType" ? "TransTypeName" : type === "UserID" ? "UserIDName" : "ModuleName"
						}}
						caption={type === "TransType" ? "Trans Type Name" : type === "UserID" ? "User Name" : "Module Name"}
						visible={type !== "Action"}
                        alignment='left'
						allowGrouping={false}
					/>

					<Selection mode='multiple' showSelectAll={true} selectAllMode='page' showCheckBoxesMode={"always"} />
					<Toolbar visible={false} />
				</DataGrid>
			</div>
		);
	}

	const dataGridOnSelectionChanged = (e, type) => {
		if (type === "TransType") {
			updateParamValue("TransTypeList", e.selectedRowKeys);
		} else if (type === "UserID") {
			updateParamValue("UserIDList", e.selectedRowKeys);
		} else if (type === "Module") {
			updateParamValue("ModuleList", e.selectedRowKeys);
		} else if (type === "Action") {
			updateParamValue("ActionList", e.selectedRowKeys);
		} 
	};

	const popFunction = (e, type) => {
		if (e.cancel === false) {
			if (type === "TransType") {
				setOpenTransTypeRange(false);
			} else if (type === "Module") {
				setOpenModuleRange(false);
			} else if (type === "UserID") {
				setOpenUserIDRange(false);
			} 
		} else {
			if (type === "TransType") {
				setOpenTransTypeRange(true);
			} else if (type === "Module") {
				setOpenModuleRange(true);
			} else if (type === "UserID") {
				setOpenUserIDRange(true);
			} 
		}
	};

	// const arrayToString = (arr) => {
	// 	if (Array.isArray(arr)) {
	// 		return arr.join(" ");
	// 	} else {
	// 		return arr;
	// 	}
	// };

	return (
		<div>
            <div>
                {/* <Popup visible={openReportPop} width={"auto"} height={"auto"} dragEnabled={false} title='Print Report' showCloseButton={false}>
                    <div className='popup-group-form-item'>
                        <div className='popup-group-form-label'>Report: </div>
                        <SelectBox
                            value={returnSelectedValue("ReportID")}
                            elementAttr={{ dataField: "ReportID" }}
                            onValueChanged={(e) => {
                                // console.log(e.value);
                                updateParamValue("ReportID", e.value);
                            }}
                            alignment='left'
                            width={"450px"}
                            dataSource={dropDownList.reportList}
                            displayExpr={"code"}
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                        />
                    </div>
                    <div className='popup-group-form-item' style={{ marginTop: "15px", justifyContent: "flex-end" }}>
                        <Button text='Cancel' type='normal' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(false)} />
                        <Button text='Print Report' type='success' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => handlePrintBtn(e)} />
                    </div>
                </Popup> */}

                <div className={"content-block"}>
                    <div>
                        <div className='listing-page-title-container'>
                            <span className='datagrid-customized-title'>
                                <PathIndicator />
                            </span>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <div style={{ backgroundColor: "#EFEFEF", borderRadius: "15px", width: "85%", padding: "0.7%" }}>
                                <div className='popup-form-item-container2'>
                                    <div className='popup-group-form-item'>
                                        <div className='popup-group-form-label'>Date :</div>
                                        <DateBox
                                            type='date'
                                            value={fromDate}
                                            elementAttr={{ dataField: "FromDate" }}
                                            useMaskBehavior={true}
                                            label='From'
                                            labelMode='floating'
                                            displayFormat='dd/MM/yyyy'
                                            dateSerializationFormat='yyyy-MM-dd'
                                            onValueChanged={(e) => {
                                                valueOnChange(e, "FromDate");
                                            }}
                                        />

                                        <DateBox
                                            type='date'
                                            value={toDate}
                                            elementAttr={{ dataField: "ToDate" }}
                                            useMaskBehavior={true}
                                            label='To'
                                            labelMode='floating'
                                            displayFormat='dd/MM/yyyy'
                                            dateSerializationFormat='yyyy-MM-dd'
                                            onValueChanged={(e) => {
                                                valueOnChange(e, "ToDate");
                                            }}
                                            style={{ marginLeft: "5%" }}
                                        />
                                    </div>

                                    <div className='popup-group-form-item'>
                                        <div className='popup-group-form-label'> Transaction Type </div>
                                        {selectedTransType === 0 && (
                                            <SelectBox
                                                width={"250px"}
                                                dataSource={filterTypes}
                                                value={selectedTransType}
                                                displayExpr={"Type"}
                                                valueExpr={"ID"}
                                                elementAttr={{ dataField: "FilterNumber" }}
                                                onValueChanged={(e) => {
                                                    valueOnChange(e, "TransType");
                                                }}
                                            />
                                        )}
                                        {selectedTransType === 1 && (
                                            <div onClick={() => setOpenTransTypeRange(true)}>
                                                <TextBox
                                                    value={returnSelectedValue("FromTransType") || returnSelectedValue("ToTransType") ? `${returnSelectedValue("FromTransType")} to ${returnSelectedValue("ToTransType")}` : "No Range Selected Yet"}
                                                    // useMaskBehavior={true} // Enable mask behavior
                                                    // mask="cccccc" // Define the input mask
                                                    id='TransType'
                                                    style={{ marginRight: "10px" }}
                                                    width={"250px"}>
                                                    <Popover
                                                        target='#TransType'
                                                        showEvent={"click"}
                                                        // hideEvent="mouseleave"
                                                        position='bottom'
                                                        visible={openTransTypeRange}
                                                        onHiding={(e) => popFunction(e, "TransType")}
                                                        dragEnabled={false}
                                                        hideOnOutsideClick={true}
                                                        width={500}
                                                        height={125}>
                                                        <div>
                                                            <div className='popup-form-item-container2'>
                                                                <div>
                                                                    <label>From</label>
                                                                    <CustomizedLookup
                                                                        ref={fromTransTypeLookupRef}
                                                                        className={"ar-listing-datagrid"}
                                                                        dataSource={fromTransTypeList}
                                                                        displayExpr={"code"}
                                                                        valueExpr={"code"}
                                                                        value={returnSelectedValue("FromTransType")}
                                                                        onSelectionChanged={(e) => {
                                                                            updateParamValue("FromTransType", e.value);
                                                                        }}
                                                                        dataSourceURL={"api/Utilities/GetMenus"}
                                                                        displayText={returnSelectedValue("FromTransType")}
                                                                        onDataSourceChanged={setFromTransTypeList}>
                                                                        <Column dataField='code' elementAttr={{ dataField: "TransTypeCode" }} visible={true} caption='Trans Type Code' alignment='left' allowGrouping={false} />
                                                                        <Column dataField='name' elementAttr={{ dataField: "TransTypeName" }} visible={true} caption='Trans Type Name' alignment='left' allowGrouping={false} />
                                                                    </CustomizedLookup>
                                                                </div>
                                                                <div>
                                                                    <label>To</label>

                                                                    <CustomizedLookup
                                                                        ref={toTransTypeLookupRef}
                                                                        className={"ar-listing-datagrid"}
                                                                        dataSource={toTransTypeList}
                                                                        displayExpr={"code"}
                                                                        valueExpr={"code"}
                                                                        value={returnSelectedValue("ToTransType")}
                                                                        displayText={returnSelectedValue("ToTransType")}
                                                                        onSelectionChanged={(e) => {
                                                                            updateParamValue("ToTransType", e.value);
                                                                        }}
                                                                        dataSourceURL={"api/Utilities/GetMenus"}
                                                                        onDataSourceChanged={setToTransTypeList}>
                                                                        <Column dataField='code' elementAttr={{ dataField: "TransTypeCode" }} visible={true} caption='Trans Type Code' alignment='left' allowGrouping={false} />
                                                                        <Column dataField='name' elementAttr={{ dataField: "TransTypeName" }} visible={true} caption='Trans Type Name' alignment='left' allowGrouping={false} />
                                                                    </CustomizedLookup>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    style={{ position: "absolute", top: "65%", left: "75%" }}
                                                                    text='Clear Filter'
                                                                    stylingMode='text'
                                                                    type='default'
                                                                    onClick={() => {
                                                                        removeParamValue("TransTypeList");
                                                                        removeParamValue("FromTransType");
                                                                        removeParamValue("ToTransType");
                                                                        setSelectedTransType(0);
                                                                    }}
                                                                    elementAttr={{ dataField: "FilterNumber" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Popover>
                                                </TextBox>
                                            </div>
                                        )}
                                        {selectedTransType === 2 && (
                                            <div>
                                                <TextBox
                                                    value={returnSelectedKeys("TransType").length !== 0 ? `${returnSelectedKeys("TransType").length} Selected  (${returnSelectedKeys("TransType")})` : `${returnSelectedKeys("TransType").length} Selected`}
                                                    style={{ marginTop: "-35px" }}
                                                    height={0}
                                                    showClearButton={false}
                                                    width={"250px"}>
                                                    <DropDownBox
                                                        // onClosed={(e) => closeDropDownBox(e, "Project")}
                                                        ref={dropdownRef}
                                                        // opened={openCustSpecific}
                                                        valueExpr='id'
                                                        deferRendering={false}
                                                        displayExpr='code'
                                                        showClearButton={false}
                                                        contentRender={(e) => DataGridMultiRender("TransType")}
                                                        defaultOpened={true}
                                                        width={"250px"}
                                                        dropDownOptions={{ width: "auto" }}
                                                    />
                                                </TextBox>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='popup-form-item-container2'>
                                    <div className='popup-group-form-item'>
                                        <div className='popup-group-form-label'> User ID </div>
                                        {selectedUserID === 0 && (
                                            <SelectBox
                                                width={"250px"}
                                                dataSource={filterTypes}
                                                value={selectedUserID}
                                                displayExpr={"Type"}
                                                valueExpr={"ID"}
                                                elementAttr={{ dataField: "FilterNumber" }}
                                                onValueChanged={(e) => {
                                                    valueOnChange(e, "UserID");
                                                }}
                                            />
                                        )}
                                        {selectedUserID === 1 && (
                                            <div onClick={() => setOpenUserIDRange(true)}>
                                                <TextBox
                                                    value={returnSelectedValue("FromUserID") || returnSelectedValue("ToUserID") ? `${returnSelectedValue("FromUserID")} to ${returnSelectedValue("ToUserID")}` : "No Range Selected Yet"}
                                                    // useMaskBehavior={true} // Enable mask behavior
                                                    // mask="cccccc" // Define the input mask
                                                    id='UserID'
                                                    style={{ marginRight: "10px" }}
                                                    width={"250px"}>
                                                    <Popover
                                                        target='#UserID'
                                                        showEvent={"click"}
                                                        // hideEvent="mouseleave"
                                                        position='bottom'
                                                        visible={openUserIDRange}
                                                        onHiding={(e) => popFunction(e, "UserID")}
                                                        dragEnabled={false}
                                                        hideOnOutsideClick={true}
                                                        width={500}
                                                        height={125}>
                                                        <div>
                                                            <div className='popup-form-item-container2'>
                                                                <div>
                                                                    <label>From</label>
                                                                    <CustomizedLookup
                                                                        ref={fromUserIDLookupRef}
                                                                        className={"ar-listing-datagrid"}
                                                                        dataSource={fromUserIDList}
                                                                        displayExpr={"username"}
                                                                        valueExpr={"username"}
                                                                        value={returnSelectedValue("FromUserID")}
                                                                        onSelectionChanged={(e) => {
                                                                            updateParamValue("FromUserID", e.value);
                                                                        }}
                                                                        dataSourceURL={"api/Utilities/GetUsers"}
                                                                        displayText={returnSelectedValue("FromUserID")}
                                                                        onDataSourceChanged={setFromUserIDList}>
                                                                        <Column dataField='email' elementAttr={{ dataField: "UserIDCode" }} visible={true} caption='User Code' alignment='left' allowGrouping={false} />
                                                                        <Column dataField='username' elementAttr={{ dataField: "UserIDName" }} visible={true} caption='User Name' alignment='left' allowGrouping={false} />
                                                                    </CustomizedLookup>
                                                                </div>
                                                                <div>
                                                                    <label>To</label>

                                                                    <CustomizedLookup
                                                                        ref={toUserIDLookupRef}
                                                                        className={"ar-listing-datagrid"}
                                                                        dataSource={toUserIDList}
                                                                        displayExpr={"username"}
                                                                        valueExpr={"username"}
                                                                        value={returnSelectedValue("ToUserID")}
                                                                        onSelectionChanged={(e) => {
                                                                            updateParamValue("ToUserID", e.value);
                                                                        }}
                                                                        dataSourceURL={"api/Utilities/GetUsers"}
                                                                        displayText={returnSelectedValue("ToUserID")}
                                                                        onDataSourceChanged={setToUserIDList}>
                                                                        <Column dataField='email' elementAttr={{ dataField: "UserIDCode" }} visible={true} caption='User Code' alignment='left' allowGrouping={false} />
                                                                        <Column dataField='username' elementAttr={{ dataField: "UserIDName" }} visible={true} caption='User Name' alignment='left' allowGrouping={false} />
                                                                    </CustomizedLookup>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    style={{ position: "absolute", top: "65%", left: "75%" }}
                                                                    text='Clear Filter'
                                                                    stylingMode='text'
                                                                    type='default'
                                                                    onClick={() => {
                                                                        removeParamValue("UserIDList");
                                                                        removeParamValue("FromUserID");
                                                                        removeParamValue("ToUserID");
                                                                        setSelectedUserID(0);
                                                                    }}
                                                                    elementAttr={{ dataField: "FilterNumber" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Popover>
                                                </TextBox>
                                            </div>
                                        )}
                                        {selectedUserID === 2 && (
                                            <div>
                                                <TextBox
                                                    value={returnSelectedKeys("UserID").length !== 0 ? `${returnSelectedKeys("UserID").length} Selected  (${returnSelectedKeys("UserID")})` : `${returnSelectedKeys("UserID").length} Selected`}
                                                    style={{ marginTop: "-35px" }}
                                                    height={0}
                                                    showClearButton={false}
                                                    width={"250px"}>
                                                    <DropDownBox
                                                        ref={dropdownRef}
                                                        // opened={openSalesSpecific}
                                                        // onClosed={(e) => closeDropDownBox(e, "Salesman")}
                                                        valueExpr='id'
                                                        deferRendering={false}
                                                        displayExpr='code'
                                                        showClearButton={false}
                                                        contentRender={() => DataGridMultiRender("UserID")}
                                                        defaultOpened={true}
                                                        width={"250px"}
                                                        dropDownOptions={{ width: "auto" }}
                                                    />
                                                </TextBox>
                                            </div>
                                        )}
                                    </div>

                                    {/* <div className='popup-group-form-item'>
                                        <div className='popup-group-form-label'> Module </div>
                                        {selectedModule === 0 && (
                                            <SelectBox
                                                width={"250px"}
                                                dataSource={filterTypes}
                                                value={selectedModule}
                                                displayExpr={"Type"}
                                                valueExpr={"ID"}
                                                elementAttr={{ dataField: "FilterNumber" }}
                                                onValueChanged={(e) => {
                                                    valueOnChange(e, "Module");
                                                }}
                                            />
                                        )}
                                        {selectedModule === 1 && (
                                            <div onClick={() => setOpenModuleRange(true)}>
                                                <TextBox
                                                    value={returnSelectedValue("FromModule") || returnSelectedValue("ToModule") ? `${returnSelectedValue("FromModule")} to ${returnSelectedValue("ToModule")}` : "No Range Selected Yet"}
                                                    // useMaskBehavior={true} // Enable mask behavior
                                                    // mask="cccccc" // Define the input mask
                                                    id='Module'
                                                    style={{ marginRight: "10px" }}
                                                    width={"250px"}>
                                                    <Popover
                                                        target='#Module'
                                                        showEvent={"click"}
                                                        // hideEvent="mouseleave"
                                                        position='bottom'
                                                        visible={openModuleRange}
                                                        onHiding={(e) => popFunction(e, "Module")}
                                                        dragEnabled={false}
                                                        hideOnOutsideClick={true}
                                                        width={500}
                                                        height={125}>
                                                        <div>
                                                            <div className='popup-form-item-container2'>
                                                                <div>
                                                                    <label>From</label>
                                                                    <CustomizedLookup
                                                                        ref={fromModuleLookupRef}
                                                                        className={"ar-listing-datagrid"}
                                                                        dataSource={fromModuleList}
                                                                        displayExpr={"code"}
                                                                        valueExpr={"code"}
                                                                        value={returnSelectedValue("FromModule")}
                                                                        onSelectionChanged={(e) => {
                                                                            updateParamValue("FromModule", e.value);
                                                                        }}
                                                                        dataSourceURL={"api/Utilities/GetModules"}
                                                                        displayText={returnSelectedValue("FromModule")}
                                                                        onDataSourceChanged={setFromModuleList}>
                                                                        <Column dataField='code' elementAttr={{ dataField: "ModuleCode" }} visible={true} caption='Module Code' alignment='left' allowGrouping={false} />
                                                                        <Column dataField='name' elementAttr={{ dataField: "ModuleName" }} visible={true} caption='Module Name' alignment='left' allowGrouping={false} />
                                                                    </CustomizedLookup>
                                                                </div>
                                                                <div>
                                                                    <label>To</label>

                                                                    <CustomizedLookup
                                                                        ref={toModuleLookupRef}
                                                                        className={"ar-listing-datagrid"}
                                                                        dataSource={toModuleList}
                                                                        displayExpr={"code"}
                                                                        valueExpr={"code"}
                                                                        value={returnSelectedValue("ToModule")}
                                                                        onSelectionChanged={(e) => {
                                                                            updateParamValue("ToModule", e.value);
                                                                        }}
                                                                        dataSourceURL={"api/Utilities/GetModules"}
                                                                        displayText={returnSelectedValue("ToModule")}
                                                                        onDataSourceChanged={setToModuleList}>
                                                                        <Column dataField='code' elementAttr={{ dataField: "ModuleCode" }} visible={true} caption='Module Code' alignment='left' allowGrouping={false} />
                                                                        <Column dataField='name' elementAttr={{ dataField: "ModuleName" }} visible={true} caption='Module Name' alignment='left' allowGrouping={false} />
                                                                    </CustomizedLookup>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    style={{ position: "absolute", top: "65%", left: "75%" }}
                                                                    text='Clear Filter'
                                                                    stylingMode='text'
                                                                    type='default'
                                                                    onClick={() => {
                                                                        removeParamValue("ModuleList");
                                                                        removeParamValue("FromModule");
                                                                        removeParamValue("ToModule");
                                                                        setSelectedModule(0);
                                                                    }}
                                                                    elementAttr={{ dataField: "FilterNumber" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Popover>
                                                </TextBox>
                                            </div>
                                        )}
                                        {selectedModule === 2 && (
                                            <div>
                                                <TextBox
                                                    value={returnSelectedKeys("Module").length !== 0 ? `${returnSelectedKeys("Module").length} Selected  (${returnSelectedKeys("Module")})` : `${returnSelectedKeys("Module").length} Selected`}
                                                    style={{ marginTop: "-35px" }}
                                                    height={0}
                                                    showClearButton={false}
                                                    width={"250px"}>
                                                    <DropDownBox
                                                        ref={dropdownRef}
                                                        valueExpr='id'
                                                        deferRendering={false}
                                                        displayExpr='code'
                                                        showClearButton={false}
                                                        contentRender={() => DataGridMultiRender("Module")}
                                                        defaultOpened={true}
                                                        width={"250px"}
                                                        dropDownOptions={{ width: "auto" }}
                                                    />
                                                </TextBox>
                                            </div>
                                        )}
                                    </div> */}
                                </div>

                                <div className='popup-form-item-container2'>
                                    <div className='popup-group-form-item'>
                                        <div className='popup-group-form-label'> Action </div>
                                        {selectedAction === 0 && (
                                            <SelectBox
                                                width={"250px"}
                                                dataSource={filterTypes.filter(x => x.ID !== 1)}
                                                value={selectedAction}
                                                displayExpr={"Type"}
                                                valueExpr={"ID"}
                                                elementAttr={{ dataField: "FilterNumber" }}
                                                onValueChanged={(e) => {
                                                    valueOnChange(e, "Action");
                                                }}
                                            />
                                        )}
                                        {selectedAction === 2 && (
                                            <div>
                                                <TextBox
                                                    value={returnSelectedKeys("Action").length !== 0 ? `${returnSelectedKeys("Action").length} Selected  (${returnSelectedKeys("Action")})` : `${returnSelectedKeys("Action").length} Selected`}
                                                    style={{ marginTop: "-35px" }}
                                                    height={0}
                                                    showClearButton={false}
                                                    width={"250px"}>
                                                    <DropDownBox
                                                        ref={dropdownRef}
                                                        // opened={openAreaSpecific}
                                                        // onClosed={(e) => closeDropDownBox(e, "Area")}
                                                        valueExpr='id'
                                                        deferRendering={false}
                                                        displayExpr='code'
                                                        showClearButton={false}
                                                        contentRender={() => DataGridMultiRender("Action")}
                                                        defaultOpened={true}
                                                        width={"250px"}
                                                        dropDownOptions={{ width: "auto" }}
                                                    />
                                                </TextBox>
                                            </div>
                                        )}
                                    </div>

                                    <div className='popup-group-form-item' style={{ marginTop: "10px" }}>
                                        <Button text='Inquiry' type='success' width={"auto"} onClick={(e) => onInitialized(e)} />
                                        <Button text='Clear' type='success' stylingMode={"outlined"} style={{ marginLeft: "20px" }} width={"auto"} onClick={() => clearFilters()} />
                                        {/* <Button text='Print Report' disabled={printRepDisabled} type='success' stylingMode={"outlined"} width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(true)} /> */}
                                    </div>
                                </div>
                            </div>

                            <DataGrid ref={datagridRef} keyExpr={keyDetail} dataSource={mainDataSource} showBorders={true} paging={false} 
                                onRowPrepared={(e) => {  
									// console.log(utils.isNullOrEmpty(e.rowType['data']));
									// console.log(e);
									if (e.rowType === 'data') {  
										if(!utils.isNullOrEmpty(e.data["CanExpand"])) {
											if(!e.data["CanExpand"]) {
                                                if(!utils.isNullOrEmpty(e.cells[0])) {
                                                    e.cells[0].cellElement.classList.remove('dx-datagrid-expand');  
                                                    e.cells[0].cellElement.childNodes[0].classList.remove('dx-datagrid-group-closed');  
                                                }
											}
										}
									}  
								}}
                                columnHidingEnabled={true}>
                                <MasterDetail enabled={true} component={dataTemplate}/>
                                {/* <Paging defaultPageSize={15} /> */}
                                <Column key={"ModuleID"} dataField={"ModuleID"} visible={false} />
                                <Column key={"CreatedDate"} dataField={"CreatedDate"} visible={false} />
                                <Column key={"Line"} dataField={"Line"} caption={"No"} alignment={"left"} width={"50px"} />
                                <Column key={"DateTime"} dataField={"DateTime"} format='dd/MM/yyyy HH:mm:ss' dataType={"date"} width={"200px"} />
                                <Column key={"UserName"} dataField={"UserName"} caption={"User ID"} width={"200px"} />
                                <Column key={"Action"} dataField={"Action"} width={"150px"} />
                                <Column key={"Reference"} dataField={"Reference"} hidingPriority={0} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
}

import utils, { download, downloadget, httpdel, httpget, httppost, upload } from '../utils/common';
import { useState, useEffect } from 'react';

var _root = window.location.origin.toLowerCase();
const isLocalhost = _root.indexOf('localhost') >= 0;

const apiurl = isLocalhost ? 'https://localhost:7254' : 'https://onlinetest-api.onlinestar.com.my';

export function getUrl(url) {
    if (url.indexOf('http') >= 0)
        return url;
    else
        return utils.pathCombine(apiurl, url);
}

const baseapi = {
    url: utils.fromRoot(apiurl),
    getUrl,
    isLocalhost,
    options: () => {
        const auth = localStorage.getItem('Authorization');
        const user = JSON.parse(localStorage.getItem('data'));
        const headers = {};

        if (auth) headers.Authorization = auth;
    
        return {
            headers
        };
    },
    setAuthorization: (token) => {
        if (token)
            localStorage.setItem('Authorization', 'Bearer ' + token);
        else
            localStorage.setItem('Authorization', '');
    },
    getToken: () => {
        return (localStorage.getItem('Authorization') || '').replace('Bearer ', '');
    },
    httpget: (url, data, options) => {
        return httpget(getUrl(url), data, {
            ...baseapi.options(),
            ...options
        }).catch(baseapi.handleError);
    },
    httppost: (url, data, options) => {
        return httppost(getUrl(url), data, {
            ...baseapi.options(),
            ...options
        }).catch(baseapi.handleError);
    },
    httpdel: (url, data, options) => {
        return httpdel(getUrl(url), data, {
            ...baseapi.options(),
            ...options
        }).catch(baseapi.handleError);
    },
    upload: (url, data, options) => {
        return upload(getUrl(url), data, {
            ...baseapi.options(),
            ...options
        }).catch(baseapi.handleError);
    },
    download: (url, data, options) => {
        return download(getUrl(url), data, {
            ...baseapi.options(),
            ...options
        }).catch(baseapi.handleError);
    },
    downloadget: (url, data, options) => {
        return downloadget(getUrl(url), data, {
            ...baseapi.options(),
            ...options
        }).catch(baseapi.handleError);
    }
};

export default baseapi;

import React, { useState, useEffect, useCallback, useRef } from "react";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import baseapi from "../../../api/baseapi";
import utils from "../../../utils/common";
import DataGrid, { Column, Editing, Selection, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem, LoadPanel } from "devextreme-react/data-grid";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { loading, closeLoading } from "../../../utils/common";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import { Validator, RequiredRule as ValidatorRequired, CustomRule } from "devextreme-react/validator";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import DataGridAsyncBox from "../../../components/datagrid-async-box/DataGridAsyncBox";
import { receiptReportNames } from "../../../utils/lookupstore";
import { Template } from "devextreme-react/core/template";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation } from "../../../utils/module-calculation";
import GainAndLossForm from "../../../components/popup-form/gain-and-loss-form/GainAndLossForm";
import AsyncDateBox from "../../../components/async-date-box/AsyncDateBox";

export default function ARReceiptFormBFY(props) {
	const formID = useRef(null);
	const formRef = useRef(null);

	const [startValidation, setStartValidation] = useState(0);
	const [isEditing, setIsEditing] = useState(false);
	const [lockedForm, setLockedForm] = useState(false);

	const customerLookupRef = useRef(null);
	const journalTypeLookupRef = useRef(null);
	const forexLookupRef = useRef(null);
	const [formValue, setFormValue] = useState({});
	const projectLookupRef = useRef(null);
	const areaLookupRef = useRef(null);
	const salesmanLookupRef = useRef(null);
	const [reportDocumentNo, setReportDocumentNo] = useState(null);

	// Ref to track if we are in the middle of a bulk update
	const isBulkUpdating = useRef(false);

	// useEffect to re-enable onValueChanged handlers after state update
	useEffect(() => {
		if (isBulkUpdating.current) {
			// Re-enable handlers after the state update is finished
			isBulkUpdating.current = false;
		}
	}, [formValue]); // This effect runs whenever formValues is updated

	useEffect(() => {
		if (!utils.isNullOrEmpty(formValue["DocumentNo"])) {
			setReportDocumentNo(formValue["DocumentNo"]);
		}
	}, [formValue["DocumentNo"]]);

	useEffect(() => {
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);

	// Handler for setting values for all TextBoxes
	const setAllValues = (newValues) => {
		isBulkUpdating.current = true; // Disable onValueChanged handlers
		setFormValue(newValues); // Set form values
	};

	// Handler for individual TextBox value change
	const handleValueChange = (e, receivedDataField = null) => {
		if (!isBulkUpdating.current) {
			try {
				// console.log("e", e)
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				const obj = {};

				obj[dataField] = e.value;

				if(dataField === "ProjectID" && !utils.isNullOrEmpty(e.selectedItem)){
					const selectedItem = e.selectedItem;
					obj["ProjectCode"] = selectedItem?.code;
				}
				else if(dataField === "SalesmanID" && !utils.isNullOrEmpty(e.selectedItem)){
					const selectedItem = e.selectedItem;
					obj["SalesmanCode"] = selectedItem?.code;
				}
				else if(dataField === "AreaID" && !utils.isNullOrEmpty(e.selectedItem)){
					const selectedItem = e.selectedItem;
					obj["AreaCode"] = selectedItem?.code;
				}
				else if(dataField === "MainForexAmount"){
					obj["OutstandingAmount"] = e.value;
					obj["MainLocalAmount"] = e.value;
				}

				setFormValue((prevValues) => ({
					...prevValues,
					...obj
				}));
			} catch (error) {
				console.log("error", error)
			}	
		}
	};


	function handleSubmit(e) {
		const submittedForm = {
			Parent: formValue,
			Children: []
		};

		loading(`Saving ${props.title}...`);
		baseapi
			.httppost(
				utils.extendUrlVar("/api/ARReceipt/save", {
					id: formID.current || "",
					del: false
				}),
				submittedForm
			)
			.then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
				if (data.docNo) setReportDocumentNo(data.docNo);

				if(!utils.isNullOrEmpty(props.onSaving)){
					const onSavingReturn = {
						Parent: formValue,
						ChildrenID : props.childrenID,
						ApiURL : props.apiURL,
						isDebtor : true
					}
		
					props.onSaving(onSavingReturn);
				}
			});
	}

	function onInitialized(e) {
		if (formID.current === "new") loading(`Loading New ${props.title}...`);
		else loading(`Loading Existing ${props.title}...`);

		baseapi.httpget("/api/ARReceipt/Get", { id: formID.current || "new" }).then((response) => {
			const { data } = response;
			utils.popupFormAfterInitialization(formRef, props, data);

			var parentData = data.model.Parent;

			if(!utils.isNullOrEmpty(props.formData) && utils.isObject(props.formData)){
				parentData = utils.mergeObject(parentData, props.formData);
			}

			setAllValues(parentData);
			closeLoading();

			if(data.isOffset){
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: "This Receipt cannot be edited because it already offset other document(s)",
					type: "Warning",
				});
				setLockedForm(true);
			}
			else{
				setLockedForm(utils.receiptMessage(formRef, data));
			}
		});
	}

	const clearForm = () => {
		props.closePopup(null);
		setAllValues({});
	};

	return (
		<div>
			<PopupForm
				ref={formRef}
				ReportDocumentNo={reportDocumentNo}
				onClosing={clearForm}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				headerClassName={"ar-module-form-title-bg popup-form-title"}
				title={props.title}
				apiURL={props.apiURL}
				disableButtons={lockedForm}
				actionButtonEnabled={false}
				saveButtonOnly={!lockedForm}
				creditChecking={false}
				onShown={onInitialized}
				requiredColumns={{
					Children: ["ChartAccountID"]
				}}
				filterRules={{
					Children: [
						{ name: "ChartAccountID", rules: [{ name: "Required" }] },
						{ name: "Description", rules: [{ name: "Required" }] },
						{ name: "ForexAmount", rules: [{ name: "Min", value: 1 }] }
					]
				}}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				copyFields={{
					Parent: formValue,
				}}
				onPasteForm={(e) => {
					setAllValues(e.Parent);
				}}
				copyStorage={"ARReceiptCopiedData"}
				formData={formValue}
				defaultSelectedReport={"cevUd6pLIEo%3d"}
				reportURL={receiptReportNames.url}
				onValidating={setStartValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				lookupValidateRef={[customerLookupRef, forexLookupRef, projectLookupRef, areaLookupRef, salesmanLookupRef]}
				onSaving={handleSubmit}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Cancelled"]}
							elementAttr={{ dataField: "Cancelled" }}
							onValueChanged={(e) => {
								handleValueChange(e);
							}}
							alignment='left'
							width={"12px"}
							height={"12px"}
							iconSize={"12px"}
							readOnly={lockedForm}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text'>Cancelled</span>
					</span>
				}>
				<div className='popup-form-main-content'>
					<div className='ar-module-border-bottom custom-form-grid-section1'>
						<div className=''>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Customer Code: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={customerLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["CustomerID"]}
											onSelectionChanged={(e) => {
												handleValueChange(e, "CustomerID");
											}}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Customer Code is required!"}
											dataSourceURL={"api/Utilities/GetCustomers"}
											readOnly={true}
											displayText={formValue["CustomerCode"]}
										>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Customer Name"}></Column>
											<Column dataField='forexCode' caption='Currency'></Column>
										</CustomizedLookup>
									</div>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Customer Name: </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["CustomerName"]}
										elementAttr={{ dataField: "CustomerName" }}
										onValueChanged={(e) => {
											handleValueChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={true}>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Customer Name is required!"} />}</Validator>
									</TextBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Project: </div>

								<div className='popup-group-form-input' id='customized-lookup1'>
									<CustomizedLookup
										ref={projectLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["ProjectID"]}
										onSelectionChanged={(e) => {
											handleValueChange(e, "ProjectID");
										}}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Project is required!"}
										dataSourceURL={"api/Utilities/GetProjects"}
										readOnly={lockedForm}
										displayText={formValue["ProjectCode"]}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Project Name"}></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Description: </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["Description"]}
										elementAttr={{ dataField: "Description" }}
										onValueChanged={(e) => {
											handleValueChange(e, "Description");
										}}
										alignment='left'
										width={"auto"}
										readOnly={lockedForm}
									/>
								</div>
							</div>
						</div>

						<div>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label next-doc-label'>Receipt No: </div>

									<div className='popup-group-form-input'>
										<AsyncTextBox
											value={formValue["DocumentNo"]}
											elementAttr={{ dataField: "DocumentNo" }}
											onValueChanged={(e) => {
												handleValueChange(e);
											}}
											placeholder={"<NEW>"}
											alignment='left'
											width={"auto"}
											disabled={formID.current !== "new" || lockedForm}
											asyncURL={"api/ARReceipt/CheckDuplicateDocNo"}
											asyncMessage={"This Receipt No is already taken!"}
											startValidation={startValidation !== 0}
											requiredMessage={"Document No is required!"}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Date: </div>

									<div className='popup-group-form-input'>
										<AsyncDateBox
											value={formValue["DocumentDate"]}
											elementAttr={{ dataField: "DocumentDate" }}
											onValueChanged={(e) => {
												handleValueChange(e);
											}}
											alignment='left'
											width={"auto"}
											displayFormat='dd/MM/yyyy'
											dateSerializationFormat='yyyy-MM-dd'
											useMaskBehavior={true}
											readOnly={lockedForm === true}
											asyncURL={"api/Utilities/CheckBeforeFiscalYear"}
											asyncMessage={"Date must set before current fiscal year"}
											asyncProperty={"inputDate"}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Ref. No: </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["Remarks"]}
											elementAttr={{ dataField: "Remarks" }}
											onValueChanged={(e) => {
												handleValueChange(e);
											}}
											alignment='left'
											width={"auto"}
											readOnly={lockedForm}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Terms: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["CreditTermsID"]}
											onSelectionChanged={(e) => {
												handleValueChange(e, "CreditTermsID");
											}}
											displayText={formValue["CreditTermsCode"]}
											dataSourceURL={"api/Utilities/GetCreditTerms"}
											readOnly={lockedForm}
										>
											<Column dataField='code'></Column>
											<Column dataField='name' caption='Days'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Salesman: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={salesmanLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["SalesmanID"]}
											onSelectionChanged={(e) => {
												handleValueChange(e, "SalesmanID");
											}}
											dataSourceURL={"api/Utilities/GetSalesmans"}
											readOnly={lockedForm}
											displayText={formValue["SalesmanCode"]}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Salesman is required!"}
										>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Area: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={areaLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["AreaID"]}
											onSelectionChanged={(e) => {
												handleValueChange(e, "AreaID");
											}}
											displayText={formValue["AreaCode"]}
											dataSourceURL={"api/Utilities/GetAreas"}
											readOnly={lockedForm}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Area is required!"}
										>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Forex: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={forexLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ForexID"]}
											onSelectionChanged={(e) => {
												handleValueChange(e, "ForexID");
											}}
											required={true}
											requiredMessage={"Forex is required!"}
											readOnly={true}
											dataSourceURL={"api/Utilities/GetForexs"}
											preread={true}
										>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Local Rate: </div>

									<div className='popup-group-form-input'>
										<NumberBox
											value={formValue["LocalRate"]}
											elementAttr={{ dataField: "LocalRate" }}
											onValueChanged={(e) => {
												handleValueChange(e);
											}}
											alignment='left'
											width={"auto"}
											format={utils.LocalRateFormat}
											readOnly={formValue["ForexID"] === utils.getDefaultForexRM()}>
											<Validator>{startValidation !== 0 && <ValidatorRequired message={"Local Rate is required!"} />}</Validator>
										</NumberBox>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Issue By: </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["IssueBy"]}
											elementAttr={{ dataField: "IssueBy" }}
											onValueChanged={(e) => {
												handleValueChange(e);
											}}
											alignment='left'
											width={"auto"}
											readOnly={true}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='ar-module-border-bottom'>
						<div className="popup-form-item-container1">
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Total Amount: </div>

								<div className='popup-group-form-input' style={{ width: "150px" }}>
									<NumberBox
										value={formValue["MainForexAmount"]}
										elementAttr={{ dataField: "MainForexAmount" }}
										onValueChanged={(e) => {
											handleValueChange(e);
										}}
										alignment='left'
										width={"auto"}
										format='#,##0.00'
										readOnly={lockedForm}
									>
										{/* <Validator 
										>
											<ValidatorRequired message={"Outstanding is required!"} />
										</Validator> */}
									</NumberBox>
								</div>
							</div>
						</div>

						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Local Amount: </div>

								<div className='popup-group-form-input' style={{ width: "150px" }}>
									<NumberBox
										value={formValue["MainLocalAmount"]}
										elementAttr={{ dataField: "MainLocalAmount" }}
										onValueChanged={(e) => {
											handleValueChange(e);
										}}
										alignment='left'
										width={"auto"}
										format='#,##0.00'
										readOnly={true}>
										
									</NumberBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Unapplied Amount: </div>

								<div className='popup-group-form-input' style={{ width: "150px" }}>
									<NumberBox
										value={formValue["OutstandingAmount"]}
										text={0}
										elementAttr={{
											dataField: "OutstandingAmount",
											class: "outstanding-amount-color"
										}}
										onValueChanged={(e) => {
											handleValueChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={true}
										format='#,##0.00'>
										{/* <Validator 
                                    >
                                        <ValidatorRequired message={"Outstanding is required!"} />
                                    </Validator> */}
									</NumberBox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</PopupForm>
		</div>
	);
}

import React, { useCallback, useEffect, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import baseapi from '../../api/baseapi';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { SelectBox } from 'devextreme-react/select-box';
const PlanSelectionForm = ({ visible, onClose }) => {
    const [selectedPlanID, setSelectedPlanID] = useState(null);
    const [companyPlan, setCompanyPlan] = useState(null);
    const [userCount, setUserCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [userLicensePrice, setUserLicensePrice] = useState(0);
    const [initialPlanID, setInitialPlanID] = useState(null);
    const [initialUserCount, setInitialUserCount] = useState(0);
    const { setUser } = useAuth();
    const navigate = useNavigate();
    const [popupWidth, setPopupWidth] = useState("55%");
    const [planPrices, setPlanPrices] = useState({});
    const [selectedDuration, setSelectedDuration] = useState(null);
    const [dropDownList, setDropDownList] = useState({
        planDurationList: []
    });
    const [plans, setPlans] = useState([]);
    const [chargeableUsers, setChargeableUsers] = useState(0);

    useEffect(() => {
        const updatePopupWidth = () => {
            if (window.innerWidth < 768) {
                setPopupWidth("95%");
            } else {
                setPopupWidth("55%");
            }
        };

        updatePopupWidth();
        window.addEventListener('resize', updatePopupWidth);

        return () => {
            window.removeEventListener('resize', updatePopupWidth);
        };
    }, []);


    useEffect(() => {
        if (planPrices && selectedPlanID && userCount !== null && selectedDuration) {
            updateTotalPrice(userCount, selectedPlanID, selectedDuration);
        }
    }, [planPrices, selectedPlanID, userCount, selectedDuration]);

    useEffect(() => {
        if (visible) {
            const fetchCompanies = async () => {
                try {
                    const [planResponse, companyResponse, planDurationResponse] = await Promise.all([
                        baseapi.httpget('/api/register/GetGreenPlusPlans'),
                        baseapi.httpget('/api/UserCompany/CompanyPlanList'),
                        baseapi.httpget('/api/register/GetGreenPlusPlansDuration')
                    ]);

                    const planData = planResponse.data.reduce((acc, plan) => {
                        acc[plan.id] = {
                            LicensedUserPrice: plan.LicensedUserPrice,
                            Fee: plan.Fee
                        };
                        return acc;
                    }, {});
                    setPlanPrices(planData);

                    //set the plans 
                    setPlans(planResponse.data);


                    //set company
                    const companyData = companyResponse.data;
                    //set id 
                    setCompanyPlan(companyData);
                    setSelectedPlanID(companyData?.PlanID);
                    setInitialPlanID(companyData?.PlanID);


                    //set lincensed user 
                    const licensedUser = companyData?.LicensedUser || 0;
                    setUserCount(licensedUser);
                    setInitialUserCount(licensedUser);

                    // Set plan duration list from the new API call
                    setDropDownList({
                        planDurationList: planDurationResponse.data
                    });

                    const durationValue = companyData?.Duration;
                    if (durationValue) {
                        // Convert to string
                        setSelectedDuration(String(durationValue));
                    }

                } catch (error) {
                    console.error('Error fetching companies:', error.message);
                }
            };

            fetchCompanies();
        }
    }, [visible]);

    const updateChargeableUsers = (userCount, planID) => {
        // Find the plan with the given ID from the plans state
        const plan = plans.find(plan => plan.id === planID);
    
        // If the plan is not found, assume there are no free users
        const freeUsers = plan ? plan.DefaultLicensedUser : 0;
    
        // Calculate the number of chargeable users
        const calculatedChargeableUsers = Math.max(userCount - freeUsers, 0);
    
        // Update the state for chargeable users
        setChargeableUsers(calculatedChargeableUsers);
    
        return calculatedChargeableUsers;
    };

    const updateTotalPrice = (userCount, planID, duration) => {
        const plan = planPrices[planID];

        const planFee = plan ? plan.Fee : 0;
        const planUserCountPrice = plan ? plan.LicensedUserPrice : 0;

        // Update chargeable users and use it for price calculation
        const calculatedChargeableUsers = updateChargeableUsers(userCount, planID);

        // Calculate the licensed user price based on the chargeable users and selected duration
        const licensedUserPrice = calculatedChargeableUsers * planUserCountPrice * duration;

        // Update the userLicensePrice state with the calculated price
        setUserLicensePrice(licensedUserPrice);


        // Calculate the total price based on the plan fee, chargeable users, and selected duration
        const totalPrice = ((planFee * duration) + (calculatedChargeableUsers * planUserCountPrice * duration));

        // Update the totalPrice state with the calculated price
        setTotalPrice(totalPrice);
    };


    const handleIncrement = () => {
        setUserCount(prevCount => {
            const newCount = prevCount + 1;
            return newCount;
        });
    };

    const handleDecrement = () => {
        setUserCount(prevCount => {
            const plan = plans.find(p => p.id === selectedPlanID);
            if (!plan) return prevCount;

            if (selectedPlanID === plan.id && prevCount <= plan.DefaultLicensedUser) {
                return plan.DefaultLicensedUser; // Ensure userCount stays at DefaultLicensedUser or above
            }

            const newCount = prevCount > companyPlan?.LicensedUser ? prevCount - 1 : prevCount;
            return newCount;
        });
    };
    const handlePlanSelection = (planID) => {
        setSelectedPlanID(planID);

        const plan = plans.find(p => p.id === planID);
        if (plan) {
            if (userCount < plan.DefaultLicensedUser) {
                setUserCount(plan.DefaultLicensedUser);
            }
        }
    };

    const handleSubmit = () => {
        const planChanged = selectedPlanID !== initialPlanID;
        const userCountChanged = userCount !== initialUserCount;

        let message = 'You have made the following changes:<br>';
        if (planChanged) {
            const planName = getPlanName(selectedPlanID); // Function to get plan name based on ID
            message += `<b> - Change plan to ("${planName}")</b><br>`;
        }

        // Skip user count change message for Plan 2 with user count 2 or Plan 3 with user count 3
        if (userCountChanged) {
            const skipUserCountChangeMessage =
                (selectedPlanID === '8uSJUCajIf8%3d' && userCount === 2) ||
                (selectedPlanID === 'b_xPwSzI5Y0%3d' && userCount === 3);

            if (!skipUserCountChangeMessage) {
                message += `<b>- ${userCount > initialUserCount ? 'Increase' : 'Decrease'} user count to ${userCount}</b><br>`;
            }
        }

        if (planChanged || userCountChanged) {
            Swal.fire({
                title: 'Are you sure?',
                html: message, // Use html for HTML formatting
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, apply changes',
                cancelButtonText: 'Cancel'
            }).then(result => {
                if (result.isConfirmed) {
                    baseapi.httppost('/api/UserCompany/SwitchCompanyPlan', {
                        planID: selectedPlanID,
                        licensedUserCount: userCount
                    }).then(response => {
                        const { data } = response;
                        if (data.status) {
                            notify(data.message, 'success', 2000);
                            onClose();
                        } else {
                            notify(data.message, 'error', 2000);
                        }
                    }).catch(error => {
                        console.error('Error occurred while submitting:', error.message);
                    });
                }
            });
        } else {
            onClose();
        }
    };

    const handleOnClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    // Function to get plan name based on ID
    const getPlanName = (planID) => {
        const plan = plans.find(p => p.id === planID);
        return plan ? plan.Name : 'Unknown';
    };

    // Determine which plans should be disabled
    const isPlanDisabled = (planID) => {
        if (!companyPlan) return false;
        const currentPlanID = companyPlan.PlanID;

        // Disable plans based on the selected plan
        if (currentPlanID === 'b_xPwSzI5Y0%3d') {
            return planID !== 'b_xPwSzI5Y0%3d'; // Plan 3
        }
        if (currentPlanID === '8uSJUCajIf8%3d') {
            return planID === 'lw0I0Mvhves%3d'; // Plan 2
        }
        return false;
    };
    const handleDurationChange = (e) => {
        const duration = e.value;
        setSelectedDuration(duration);
    };


    return (
        <>
            <Popup
                visible={visible}
                onHiding={onClose}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                width={popupWidth}
                height={"auto"}
                title='Change your plan'
            >
                <div className="plan-selection-section">
                    <div className="plan-selection-section__plans">
                        {plans.map((plan) => (
                            <div
                                key={plan.id}
                                className={`plan-selection-section__plan ${selectedPlanID === plan.id ? 'plan-selection-section__plan-selected' : ''} ${isPlanDisabled(plan.id) ? 'plan-disabled' : ''}`}
                                onClick={() => !isPlanDisabled(plan.id) && handlePlanSelection(plan.id)}
                            >
                                <div className="plan-selection-wrapper">
                                    <div className="plan-selection-section__plan-name">{plan.Name}</div>
                                    <div className="plan-selection-section__plan-description">{plan.Description}</div>
                                </div>
                                <div className="plan-selection-section__plan-price">RM {plan.Fee} / month</div>
                            </div>
                        ))}
                    </div>
                    <hr className="hr-vertical" />

                    <div className="plan-selection-section__users">
                        <div className="plan-selection-section__users-title">Users</div>
                        <div className="plan-selection-section__user-counter">
                            <button
                                className={`plan-selection-section__user-decrement ${userCount <= companyPlan?.LicensedUser || (selectedPlanID === 'b_xPwSzI5Y0%3d' && userCount <= 3) || (selectedPlanID === '8uSJUCajIf8%3d' && userCount <= 2) ? 'disabled' : ''}`}
                                onClick={handleDecrement}
                                disabled={userCount <= companyPlan?.LicensedUser}
                            >
                                <i className="fa fa-minus"></i>
                            </button>
                            <div className="plan-selection-section__user-number">{userCount}</div>
                            <button
                                className="plan-selection-section__user-increment"
                                onClick={handleIncrement}
                            >
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                        <div className="plan-selection-section__total-price">
                            <b><span className="price-highlight">RM {userLicensePrice}</span></b> per month total
                        </div>
                        <div className="company-selection-buttons">
                            <button className="company-selection-cancel-btn" onClick={handleOnClose}>Cancel</button>
                            <button className="company-selection-continue-btn" onClick={handleSubmit}>Continue</button>
                        </div>
                    </div>
                </div>

                <div className="plan-selection-section">
                    <div className="plan-selection-section__plans">


                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label htmlFor="duration-selectbox" style={{ marginRight: '40px' }}>Duration</label>
                            <SelectBox
                                id="duration-selectbox"
                                dataSource={dropDownList.planDurationList}
                                onValueChanged={handleDurationChange}
                                value={selectedDuration}
                                alignment="left"
                                width="100%"
                                displayExpr={"Name"}
                                valueExpr={"Duration"}
                            />
                        </div>
                    </div>

                    <hr className="hr-vertical" />
                    <div className="plan-selection-section__users">
                        <div class="popup-subscription-plan-container">
                            <div class="popup-subscription-total-text">Total</div>
                            <div className="price-highlight">RM {totalPrice}</div>
                            <div className="subscription-plan-title">
                                &nbsp; [ RM {planPrices[selectedPlanID]?.Fee || 0}  x {selectedDuration || 0} month(s) ]
                                + [ RM {planPrices[selectedPlanID]?.LicensedUserPrice || 0} x {chargeableUsers} User(s) x {selectedDuration || 0} month(s) ]
                            </div>
                        </div>
                    </div>
                </div>

            </Popup>
        </>
    );
};

export default PlanSelectionForm;

